import React from 'react';
import './styles.css';

const MapLoader = (props) => {
	const { style } = props;
	return (
		<div className="map-loader overlay" style={style}>
			<h3 className="white-box">Fetching layers...</h3>
		</div>
	);
};

export default MapLoader;
