import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { Button } from 'primereact/button';
import { useTranslate } from '@tolgee/react';
import { MapDialogBoxPlot, MapDialogBarChart } from './components';
import translate from '../../../../utils/translate';
import DataService from '../../../../services/httpService/dataService';

const MapAnalyticsDialog = (props) => {
	const { selectedRegion, setSelectedRegion, selectedPoint, setSelectedPoint, adminLayer, wmsData, activeIndex, kebele } = props;
	const { t } = useTranslate();
	const [showDialog, setShowDialog] = useState();
	const [barChartData, setBarChartData] = useState();
	const [boxPlotData, setBoxPlotData] = useState();
	const [dialogSubheader, setDialogSubheader] = useState('');
	const [position, setPosition] = useState({ right: '-1000px', bottom: '0px' });
	const dialogRef = useRef();

	useEffect(() => {
		if (!selectedRegion) return;
		if (!wmsData) return;
		let gid;
		let subHeader;
		switch (adminLayer.name) {
		case 'gadm41_ETH_1':
			gid = selectedRegion.properties.GID_1;
			subHeader = [selectedRegion.properties.NAME_1];
			break;
		case 'gadm41_ETH_2':
			gid = selectedRegion.properties.GID_2;
			subHeader = [selectedRegion.properties.NAME_1, selectedRegion.properties.NAME_2];
			break;
		case 'gadm41_ETH_3':
			gid = selectedRegion.properties.GID_3;
			subHeader = [selectedRegion.properties.NAME_1, selectedRegion.properties.NAME_2, selectedRegion.properties.NAME_3];
			break;
		default:
			break;
		}

		const layer = activeIndex === 0 ? 'subseasonal' : 'seasonal';
		DataService.getAreaStatistics(gid, layer, wmsData.id)
			.then((response) => {
				setDialogSubheader(subHeader.join(', '));
				setBoxPlotData(reorderData(response));
				// setBoxPlotData(response);
				setBarChartData(null);
				setShowDialog(true);
			})
			.catch(() => {
				setShowDialog(false);
				setTimeout(() => {
					setBarChartData(null);
					setBoxPlotData(null);
				}, 400);
			});
	}, [selectedRegion, wmsData]);

	useEffect(() => {
		if (selectedPoint.length === 0) return;
		if (!wmsData) return;
		const layer = activeIndex === 0 ? 'subseasonal' : 'seasonal';
		DataService.getPointData(selectedPoint.lat, selectedPoint.lng, layer, wmsData.options.time)
			.then((response) => {
				setDialogSubheader(`Latitude: ${parseFloat(selectedPoint.lat).toFixed(2)}, Longitude: ${parseFloat(selectedPoint.lng).toFixed(2)}`);
				setBoxPlotData(null);
				setBarChartData(reorderData(response));
				// setBarChartData(response);
				setShowDialog(true);
			})
			.catch(() => {
				setShowDialog(false);
				setTimeout(() => {
					setBarChartData(null);
					setBoxPlotData(null);
				}, 400);
			});
	}, [selectedPoint, wmsData]);

	useEffect(() => {
		if (kebele.code < 0) return;
		if (!wmsData) return;
		if (!wmsData.id) return;
		const layer = activeIndex === 0 ? 'subseasonal' : 'seasonal';
		DataService.getAreaStatistics(kebele.woreda.gid, layer, wmsData.id)
			.then((response) => {
				setDialogSubheader(kebele.value);
				setBarChartData(null);
				setBoxPlotData(reorderData(response));
				// setBarChartData(response);
				setShowDialog(true);
			})
			.catch(() => {
				setShowDialog(false);
				setTimeout(() => {
					setBarChartData(null);
					setBoxPlotData(null);
				}, 400);
			});
	}, [kebele, wmsData]);

	useEffect(() => {
		if (showDialog) {
			setPosition({ ...position, right: '0px' });
		} else {
			setPosition({ ...position, right: '-1000px' });
		}
	}, [showDialog]);

	const reorderData = (data) => {
		const newData = [];
		newData.push(data.find((datum) => datum.category === 'Below'));
		newData.push(data.find((datum) => datum.category === 'Normal'));
		newData.push(data.find((datum) => datum.category === 'Above'));
		return newData;
	};

	// const calculatePos = (event) => {
	// 	const divX = mapDiv.current.clientWidth;
	// 	const divY = mapDiv.current.clientHeight;
	//
	// 	if (event.clientX === 0 && event.clientY === 0) {
	// 		return null;
	// 	}
	// 	const newX = divX - event.clientX;
	// 	const newY = divY - event.clientY;
	// 	if (newX < 0 || newY < 0) {
	// 		return null;
	// 	} if (newX > divX - event.target.clientWidth || newY > divY - event.target.clientHeight) {
	// 		return null;
	// 	}
	// 	return { right: `${newX}px`, bottom: `${newY}px` };
	// };

	return (
		<div
			id="map-dialog"
			style={position}
			ref={dialogRef}
		>
			<div className="header">
				<div>
					<h3 className="header-text">{translate(t, 'Rainfall Probability')}</h3>
				</div>
				<Button
					className="dialog-close"
					icon="fa-solid fa-xmark"
					rounded
					text
					onClick={() => {
						setShowDialog(false);
						setTimeout(() => {
							setSelectedPoint([]);
							setSelectedRegion(null);
							setBarChartData(null);
							setBoxPlotData(null);
						}, 400);
					}}
				/>
			</div>
			<p className="subheader-text">{dialogSubheader}</p>
			{boxPlotData ? <MapDialogBoxPlot data={boxPlotData} /> : null}
			{barChartData ? <MapDialogBarChart data={barChartData} /> : null}
		</div>
	);
};

export default MapAnalyticsDialog;
