import React from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import Gleap from 'gleap';
import { Button } from 'primereact/button';

const MenuBar = () => {
	const navigate = useNavigate();
	return (
		<div className="menu-bar">
			<button
				type="button"
				onClick={() => {
					navigate('/Home');
				}}
			>
				<h1>EDACaP</h1>
			</button>
			<Button label="Take survey" onClick={() => Gleap.showSurvey('piwaun', 'survey_full')} />
		</div>
	);
};

export default MenuBar;
