import React, { useEffect, useState } from 'react';
import './styles.css';
import { Divider } from 'primereact/divider';

const MapActionGuidance = (props) => {
	const { guidanceBlur, showGuidance } = props;
	const [guidanceMessage, setGuidanceMessage] = useState('');

	useEffect(() => {
		if (showGuidance === 'point') {
			setGuidanceMessage('Click on a map point');
		} else if (showGuidance === 'region') {
			setGuidanceMessage('Click on a map area');
		}
	}, [showGuidance]);

	const setGuidanceOpacity = () => {
		if (showGuidance === 'none') {
			return { opacity: 0 };
		}
		if (guidanceBlur) {
			return { opacity: 0.5 };
		}
		return { opacity: 1 };
	};

	return (
		<div
			className="white-box guidance"
			style={setGuidanceOpacity()}
		>
			<p>{guidanceMessage}</p>
			<Divider />
			<p>Press <b>ESC</b> to cancel</p>
		</div>
	);
};

export default MapActionGuidance;
