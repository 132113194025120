import React from 'react';
import './styles.css';
import { useTranslate } from '@tolgee/react';
import translate from '../../utils/translate';

const TextCard = (props) => {
	const { title, body, section, solid, noBox } = props;
	const { t } = useTranslate();
	return (
		<div
			className={`text-card ${noBox ? 'no-box' : 'white-box'}`}
			style={solid ? { backgroundColor: 'rgba(255, 255, 255, 1)' } : { backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
		>
			{title ? <h1>{translate(t, title, `${section}-title`)}</h1> : null}
			{body ? <h3>{translate(t, body, `${section}-body`)}</h3> : null}
		</div>
	);
};

export default TextCard;
