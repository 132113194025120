// eslint-disable-next-line import/no-cycle
import makeKey from './makeKey';

const translate = (tolgee, defaultString, customKey) => {
	if (!tolgee) return undefined;
	if (customKey) {
		return tolgee(makeKey(customKey), defaultString);
	}
	return tolgee(makeKey(defaultString), defaultString);
};

export default translate;
