import React, { useRef, useState } from 'react';
import './styles.css';
import { MapComponent, MapLayerPicker, MapLegend, MapActionGuidance, MapAnalyticsDialog, MapLoader } from './components';

const MapContainer = (props) => {
	const { kebele, wmsData, mapClass, activeIndex, sideBySide } = props;

	const [adminLayer, setAdminLayer] = useState();

	const [guidanceBlur, setGuidanceBlur] = useState(false);
	const [showGuidance, setShowGuidance] = useState('none');

	const [selectedRegion, setSelectedRegion] = useState();
	const [selectedPoint, setSelectedPoint] = useState([]);

	const [loading, setLoading] = useState(true);
	const mapDiv = useRef();

	let timer;

	return (
		<div
			className={`map ${mapClass}-container`}
			onMouseMove={() => {
				setGuidanceBlur(true);
				clearTimeout(timer);
				timer = setTimeout(() => setGuidanceBlur(false), 1000);
			}}
		>
			<MapLayerPicker setAdminLayer={setAdminLayer} />
			<div className={`map-div ${mapClass}`} ref={mapDiv}>
				<MapComponent
					adminLayer={adminLayer}
					wmsData={wmsData}
					kebele={kebele}
					setShowGuidance={setShowGuidance}
					selectedRegion={selectedRegion}
					setSelectedRegion={setSelectedRegion}
					selectedPoint={selectedPoint}
					setSelectedPoint={setSelectedPoint}
					setLoading={setLoading}
					mapClass={mapClass}
					sideBySide={sideBySide}
				/>
				<MapAnalyticsDialog
					kebele={kebele}
					selectedRegion={selectedRegion}
					setSelectedRegion={setSelectedRegion}
					adminLayer={adminLayer}
					selectedPoint={selectedPoint}
					setSelectedPoint={setSelectedPoint}
					wmsData={wmsData}
					activeIndex={activeIndex}
					mapDiv={mapDiv}
				/>
			</div>
			<MapActionGuidance guidanceBlur={guidanceBlur} showGuidance={showGuidance} />
			{mapClass === 'large-map' && !sideBySide ? <MapLegend /> : null}
			<MapLoader style={loading ? { opacity: 0.8 } : { opacity: 0, height: '0px' }} />
		</div>
	);
};

export default MapContainer;
