/* eslint-disable */
import { LitElement } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { ColorScaleSetter } from './color-scale';
import { Renderer } from './renderer';
import { AxisTicksSetter } from './x-scale-axis';
import { styles } from './styles';
import { COLOR_SCALE_PROPS, AXIS_AND_X_SCALE_PROPS, DEFAULT_WIDTH, DEFAULT_HEIGHT, DEFAULT_MARGIN_BOTTOM, DEFAULT_MARGIN_LEFT, DEFAULT_MARGIN_RIGHT, DEFAULT_MARGIN_TOP, DEFAULT_TITLE_TEXT, DEFAULT_DOMAIN, DEFAULT_RANGE, DEFAULT_SCALE_TYPE, DEFAULT_MARK_TYPE, DEFAULT_TICKS, DEFAULT_TICK_FORMAT, DEFAULT_TICK_SIZE, DEFAULT_TICK_VALUES } from './constants';

const __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
	const c = arguments.length; let r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc; let 
		d;
	if (typeof Reflect === 'object' && typeof Reflect.decorate === 'function') r = Reflect.decorate(decorators, target, key, desc);
	else for (let i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
	return c > 3 && r && Object.defineProperty(target, key, r), r;
};

let ColorLegendElement = class ColorLegendElement extends LitElement {
	constructor() {
		super(...arguments);
		/**
         * The title text that displays at the top of the legend
         */
		this.titleText = DEFAULT_TITLE_TEXT;
		/**
         * The width of the SVG or categorical legend div element
         */
		this.width = DEFAULT_WIDTH;
		/**
         * The height of the SVG element
         */
		this.height = DEFAULT_HEIGHT;
		/**
         * The spacing between the legend bar and top most extent of the SVG
         */
		this.marginTop = DEFAULT_MARGIN_TOP;
		/**
         * The spacing between the legend bar and right most extent of the SVG
         */
		this.marginRight = DEFAULT_MARGIN_RIGHT;
		/**
         * The spacing between the legend bar and bottom most extent of the SVG
         */
		this.marginBottom = DEFAULT_MARGIN_BOTTOM;
		/**
         * The spacing between the legend bar and left most extent of the SVG
         */
		this.marginLeft = DEFAULT_MARGIN_LEFT;
		/**
         * The type of legend to render based on d3-scale
         */
		this.scaleType = DEFAULT_SCALE_TYPE;
		/**
         * The color scale's domain values
         */
		this.domain = DEFAULT_DOMAIN;
		/**
         * The color scale's range values
         */
		this.range = DEFAULT_RANGE;
		/**
         * The symbology used for categorical legends
         */
		this.markType = DEFAULT_MARK_TYPE;
		/**
         * The desired number of axis ticks
         */
		this.ticks = DEFAULT_TICKS;
		/**
         * The d3-format specifier to format axis tick values
         */
		this.tickFormat = DEFAULT_TICK_FORMAT;
		/**
         * The size or length of the axis ticks
         */
		this.tickSize = DEFAULT_TICK_SIZE;
		/**
         * The explicit values to be used for axis ticks
         */
		this.tickValues = DEFAULT_TICK_VALUES;
		/**
         * Handles configuring the colorScale
         */
		this.colorScaleSetter = new ColorScaleSetter(this);
		/**
         * Configures the x scale and axis ticks
         */
		this.axisTickSetter = new AxisTicksSetter(this);
		/**
         * Handles rendering of HTML/SVG markup from the scaleType
         */
		this.renderer = new Renderer(this);
	}

	get interpolator() {
		return this._interpolator;
	}

	set interpolator(value) {
		if (typeof value === 'function') {
			const oldVal = this.interpolator;
			this._interpolator = value;
			this.requestUpdate('interpolator', oldVal);
		} else {
			throw new Error('interpolator must be a function.');
		}
	}

	get tickFormatter() {
		return this._tickFormatter;
	}

	set tickFormatter(value) {
		if (typeof value === 'function') {
			const oldVal = this.tickFormatter;
			this._tickFormatter = value;
			this.requestUpdate('tickFormatter', oldVal);
		} else {
			throw new Error('tickFormatter must be a function.');
		}
	}

	/**
     * A type of d3-scale for applying color values to the legend item(s),
     * set internally by the colorScaleSetter.
     */
	get colorScale() {
		return this.colorScaleSetter.colorScale;
	}

	/**
     * A d3 linear scale used for generating axis ticks,
     * set internally by the axisTickSetter
     */
	get xScale() {
		return this.axisTickSetter.xScale;
	}

	/**
     * Invoked on each update to perform rendering tasks. This method may return any
     * value renderable by lit-html's ChildPart - typically a TemplateResult
     * @returns TemplateResult
     */
	render() {
		return this.renderer.render();
	}

	/**
     * Lit lifecycle method that is called before an update to the component's DOM
     * @param changedProps: ChangedProps
     */
	willUpdate(changedProps) {
		if (COLOR_SCALE_PROPS.some((prop) => changedProps.has(prop))) {
			this.colorScaleSetter.setColorScale();
		}
		if (AXIS_AND_X_SCALE_PROPS.some((prop) => changedProps.has(prop))) {
			this.axisTickSetter.setXScale();
			this.axisTickSetter.handleAxisTicks();
		}
	}
};
ColorLegendElement.styles = [styles];
__decorate([
	property({ type: String }),
], ColorLegendElement.prototype, 'titleText', void 0);
__decorate([
	property({ type: Number }),
], ColorLegendElement.prototype, 'width', void 0);
__decorate([
	property({ type: Number }),
], ColorLegendElement.prototype, 'height', void 0);
__decorate([
	property({ type: Number }),
], ColorLegendElement.prototype, 'marginTop', void 0);
__decorate([
	property({ type: Number }),
], ColorLegendElement.prototype, 'marginRight', void 0);
__decorate([
	property({ type: Number }),
], ColorLegendElement.prototype, 'marginBottom', void 0);
__decorate([
	property({ type: Number }),
], ColorLegendElement.prototype, 'marginLeft', void 0);
__decorate([
	property({ type: String }),
], ColorLegendElement.prototype, 'scaleType', void 0);
__decorate([
	property({ type: Array }),
], ColorLegendElement.prototype, 'domain', void 0);
__decorate([
	property({ type: Array }),
], ColorLegendElement.prototype, 'range', void 0);
__decorate([
	property({ type: String }),
], ColorLegendElement.prototype, 'markType', void 0);
__decorate([
	property({ type: Number }),
], ColorLegendElement.prototype, 'ticks', void 0);
__decorate([
	property({ type: String }),
], ColorLegendElement.prototype, 'tickFormat', void 0);
__decorate([
	property({ type: Number }),
], ColorLegendElement.prototype, 'tickSize', void 0);
__decorate([
	property({ type: Array }),
], ColorLegendElement.prototype, 'tickValues', void 0);
__decorate([
	query('svg'),
], ColorLegendElement.prototype, 'svg', void 0);
__decorate([
	property({ attribute: false }),
], ColorLegendElement.prototype, 'interpolator', null);
__decorate([
	property({ attribute: false }),
], ColorLegendElement.prototype, 'tickFormatter', null);
ColorLegendElement = __decorate([
	customElement('color-legend'),
], ColorLegendElement);
export { ColorLegendElement };
// # sourceMappingURL=color-legend-element.js.map
