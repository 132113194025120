/* eslint-disable class-methods-use-this */
import { http } from '../index';

class DataService {
	getKebeleData = async (id) => {
		const result = await http.get(`api/kebeleData/${id}`);
		return result.data;
	};

	getWeatherData = async (lat, long) => {
		const result = await http.get(`api/weatherData/${lat}/${long}`);
		return result.data;
	};

	getAreaStatistics = async (gid, tab, wmsOption) => {
		const result = await http.get(`api/statistics/${gid}/${tab}/${wmsOption}`);
		return result.data;
	};

	getPointData = async (lat, lng, type, time) => {
		const result = await http.post(`api/getfeature`, {
			type,
			time,
			bbox: [lng, lat, lng + 1, lat + 1],
		});
		return result.data;
	};

	getSeasonalOptions = async () => {
		const result = await http.get('api/seasonal');
		return result.data;
	};

	getSubseasonalOptions = async () => {
		const result = await http.get('api/subseasonal');
		return result.data;
	};

	getAdminPolygons = async (level) => {
		const result = await http.get(`/api/polygons/${level}`);
		return result.data;
	};

	getExtremeIndices = async () => {
		const result = await http.get('api/extremeIndices');
		return result.data;
	};
}

export default new DataService();
