import axios from 'axios';

export const BACKEND = process.env.REACT_APP_BACKEND_URL;

export const http = axios.create({
	timeout: 30000,
	baseURL: `${BACKEND}`,
	// withCredentials: true,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});
