import React, { useEffect, useState, useCallback, useRef } from 'react';
import L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-side-by-side';
import './styles.css';
import { MapContainer } from 'react-leaflet';
/* eslint no-underscore-dangle: 0 */

// eslint-disable-next-line func-names
L.Layer.prototype.setInteractive = function (interactive) {
	if (this.getLayers) {
		this.getLayers().forEach((layer) => {
			layer.setInteractive(interactive);
		});
		return;
	}
	if (!this._path) {
		return;
	}

	this.options.interactive = interactive;

	if (interactive) {
		L.DomUtil.addClass(this._path, 'leaflet-interactive');
	} else {
		L.DomUtil.removeClass(this._path, 'leaflet-interactive');
	}
};

const MapComponent = (props) => {
	const { kebele, wmsData, adminLayer, setShowGuidance, selectedRegion, setSelectedRegion, selectedPoint, setSelectedPoint, setLoading, mapClass, sideBySide } = props;
	const [map, setMap] = useState();
	const [init, setInit] = useState(false);
	const position = [8.5, 39.5];
	const [kebeleData, setKebeleData] = useState();
	const [layers, setLayers] = useState();
	const adminPolyLayersRef = useRef();
	const pointMarkerRef = useRef();
	const selectedRegionRef = useRef();
	const sideBySideControlRef = useRef();

	const panes = [
		{ name: 'base', style: { zIndex: 201 } },
		{ name: 'wms', style: { zIndex: 202 } },
		{ name: 'kebeleJson', style: { zIndex: 204 } },
		{ name: 'adminJson', style: { zIndex: 203 } },
	];

	// initialize map
	useEffect(() => {
		if (!map) return;
		if (!kebele) return;
		makePanes(panes);
		const newLayers = addDefaultLayers();
		if (!sideBySide) {
			addGeoman();
		}
		setLayers(newLayers);
		setInit(true);
	}, [map]);

	// load base layer
	const addDefaultLayers = () => {
		let newLayers = { ...layers };
		addLayer(
			'base',
			'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
			{
				attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
				pane: 'base',
			},
			newLayers);
		// newLayers = addAdminLayer(newLayers);
		newLayers = addKebeleLayer(newLayers);
		return newLayers;
	};

	// quarter data based on selection
	useEffect(() => {
		if (!map) return;
		if (!wmsData) return;
		let newLayers = { ...layers };
		if (sideBySide && wmsData instanceof Array) {
			newLayers = addWmsLayer(newLayers, wmsData[0], 'wmsLeft');
			newLayers = addWmsLayer(newLayers, wmsData[1], 'wmsRight');
			addSideBySide(newLayers);
		} else {
			newLayers = addWmsLayer(newLayers, wmsData, 'wms');
		}
		setLayers(newLayers);
	}, [wmsData]);

	// region kebele data, get and update map
	useEffect(() => {
		if (kebele.code > 0) {
			setKebeleData({ geoJson: kebele.geometry, marker: kebele.centroid });
		} else {
			setKebeleData(undefined);
		}
	}, [kebele]);

	// update kebele geoJson
	useEffect(() => {
		if (!map) return;
		if (!init) return;

		const newLayers = addKebeleLayer(layers);
		setLayers(newLayers);
		map.flyTo(kebeleData.marker, map.getZoom());
	}, [kebeleData]);

	// region change active layer, which is admin level
	useEffect(() => {
		if (!layers) return;
		if (!layers.adminJson) return;

		adminPolyLayersRef.current = layers.adminJson; // Object.values(layers.adminJson._layers);
	}, [layers]);

	useEffect(() => {
		if (!map) return;
		if (!init) return;

		const newLayers = addAdminLayer(layers);
		setLayers(newLayers);
		setLoading(false);
	}, [adminLayer]);

	// add geoman control toolbar
	const addGeoman = () => {
		const disableButtons = (disable) => {
			if (disable) {
				map.pm.Toolbar.setButtonDisabled('customSelectPoint', true);
				map.pm.Toolbar.setButtonDisabled('customSelectRegion', true);
			} else {
				map.pm.Toolbar.setButtonDisabled('customSelectPoint', false);
				map.pm.Toolbar.setButtonDisabled('customSelectRegion', false);
			}
		};

		// region geoman initialization
		map.pm.addControls({
			positions: {
				draw: 'topleft',
				edit: 'topleft',
				custom: 'topright',
			},
			drawMarker: false,
			drawCircleMarker: false,
			drawPolyline: false,
			drawRectangle: false,
			drawPolygon: false,
			drawCircle: false,
			drawText: false,
			editMode: false,
			dragMode: false,
			cutPolygon: false,
			removalMode: false,
			rotateMode: false,
		});

		map.pm.Toolbar.setBlockPosition('draw', 'topleft');
		// endregion

		// region point selection tool
		map.pm.Toolbar.createCustomControl({
			name: 'customSelectPoint',
			className: 'fa-solid fa-location-dot fa-1x',
			onClick: () => {
				map.on('click', handleSelectPoint);
				map.on('keydown', handleEscape);
				setShowGuidance('point');
				disableButtons(true);
			},
			toggle: false,
		});

		const handleSelectPoint = (event) => {
			setSelectedRegion(null);
			setSelectedPoint(event.latlng);
			addMarker(event.latlng, 'point');
			map.off('click', handleSelectPoint);
			setShowGuidance('none');
			disableButtons(false);
		};

		const handleEscape = (event) => {
			if (event.originalEvent.key === 'Escape') {
				adminPolyLayersRef.current.setInteractive(false);
				map.off('click', handleSelectPoint);
				map.off('click', handleSelectRegion);
				map.off('keydown', handleEscape);
				setShowGuidance('none');
				disableButtons(false);
			}
		};
		// endregion

		// region region selection tool
		map.pm.Toolbar.createCustomControl({
			name: 'customSelectRegion',
			className: 'fa-solid fa-square-dashed fa-1x',
			toggle: false,
			onClick: () => {
				adminPolyLayersRef.current.setInteractive(true);
				map.on('click', handleSelectRegion);
				map.on('keydown', handleEscape);
				setShowGuidance('region');
				disableButtons(true);
			},
		});
		const handleSelectRegion = () => {
			adminPolyLayersRef.current.setInteractive(false);
			map.off('click', handleSelectRegion);
			map.off('keydown', handleEscape);
			setShowGuidance('none');
			disableButtons(false);
		};
	};

	useEffect(() => {
		if (selectedPoint && selectedPoint.length === 0) {
			if (pointMarkerRef.current) {
				pointMarkerRef.current.remove();
				pointMarkerRef.current = null;
			}
		}
	}, [selectedPoint]);

	useEffect(() => {
		if (!selectedRegion) {
			resetSelectedRegion();
		}
	}, [selectedRegion]);

	function handleMouseOver(event) {
		const layer = event.target;
		layer.setStyle({
			fillOpacity: 0.7,
		});
	}

	function handleMouseOff(event) {
		const layer = event.target;
		layer.setStyle({
			fillOpacity: 0,
		});
	}

	const resetSelectedRegion = () => {
		if (selectedRegionRef.current) {
			selectedRegionRef.current.on('mouseover', handleMouseOver);
			selectedRegionRef.current.on('mouseout', handleMouseOff);
			selectedRegionRef.current.setStyle({
				fillOpacity: 0,
			});
		}
	};

	const addAdminLayer = (layersObject) => {
		const newLayers = { ...layersObject };

		if (Object.hasOwn(newLayers, 'adminJson')) {
			map.removeLayer(newLayers.adminJson);
			delete newLayers.adminJson;
		}

		// function adminStyle(key) {
		// 	// set line weight depending on admin level
		// 	let weightVar;
		// 	switch (key) {
		// 	case 1:
		// 		weightVar = 3;
		// 		break;
		// 	case 2:
		// 		weightVar = 3;
		// 		break;
		// 	case 3:
		// 		weightVar = 2;
		// 		break;
		// 	default:
		// 		weightVar = 2;
		// 		break;
		// 	}
		//
		// 	return ({
		// 		weight: weightVar,
		// 		opacity: 1,
		// 		fillOpacity: 0,
		// 	});
		// }

		function handleRegionClick(layer) {
			resetSelectedRegion();
			setSelectedPoint([]);
			selectedRegionRef.current = layer;
			setSelectedRegion(layer.feature);
			layer.off('mouseover', handleMouseOver);
			layer.off('mouseout', handleMouseOff);
			layer.setStyle({
				fillOpacity: 0.7,
			});
		}

		function initializePolygons(feature, layer) {
			layer.on('click', () => handleRegionClick(layer));
			layer.on('mouseover', handleMouseOver);
			layer.on('mouseout', handleMouseOff);
		}

		addLayer(
			'adminJson', adminLayer, {
				pane: 'adminJson',
				style: {
					weight: 2,
					opacity: 1,
					fillOpacity: 0,
					color: '#454545',
				},
				onEachFeature: initializePolygons,
				interactive: false,
			}, newLayers);
		return newLayers;
	};

	const addKebeleLayer = (layersObject) => {
		const newLayers = { ...layersObject };
		if (Object.hasOwn(newLayers, 'kebeleJson')) {
			map.removeLayer(newLayers.kebeleJson);
			map.removeLayer(newLayers.kebeleMarker);
			delete newLayers.kebeleJson;
			delete newLayers.kebeleMarker;
		}
		if (!kebeleData) return newLayers;
		function kebeleStyle() {
			return ({
				fillColor: 'var(--cc-bright-red)',
				weight: 2,
				opacity: 1,
				color: 'var(--cc-bright-red)', // Outline color
				fillOpacity: 0.3,
			});
		}

		addLayer('kebeleJson', kebeleData.geoJson, { style: kebeleStyle, pane: 'kebeleJson', interactive: false }, newLayers);
		newLayers.kebeleMarker = addMarker(kebeleData.marker, 'kebele');
		// eslint-disable-next-line consistent-return
		return newLayers;
	};
	// endregion

	const addWmsLayer = (layersObject, layerData, layerName) => {
		const newLayers = { ...layersObject };
		if (Object.hasOwn(newLayers, layerName)) {
			map.removeLayer(newLayers[layerName]);
			delete newLayers.adminJson;
		}

		addLayer(layerName,	layerData.geoserver_url, layerData.options, newLayers);
		return newLayers;
	};

	const addSideBySide = (layersObject) => {
		if (sideBySideControlRef.current) {
			map.removeControl(sideBySideControlRef.current);
		}
		const leftLayer = layersObject.wmsLeft;
		const rightLayer = layersObject.wmsRight;
		sideBySideControlRef.current = L.control.sideBySide(leftLayer, rightLayer).addTo(map);
	};
	// endregion

	// region map functions
	const addLayer = (layerType, data, options, index) => {
		let newLayer;
		const newLayers = { ...layers };
		if (['wms', 'base', 'wmsLeft', 'wmsRight'].includes(layerType)) {
			newLayer = L.tileLayer.wms(data, options);
		} else if (layerType === 'kebeleJson' || layerType === 'adminJson') {
			newLayer = L.geoJSON(data, options);
		}
		// eslint-disable-next-line no-param-reassign
		index[layerType] = newLayer;
		newLayer.addTo(map);
		setLayers(newLayers);
		return newLayer;
	};

	const addMarker = (coords, type) => {
		let marker;
		const customIcon = L.divIcon({
			html: '<i class=\'fa-solid fa-location-dot\'>',
			iconSize: [30, 42],
			iconAnchor: [15, 42],
		});
		if (type === 'point') {
			if (!pointMarkerRef.current) {
				customIcon.options.className = 'custom-div-icon-blue';
				marker = L.marker([coords.lat, coords.lng], { icon: customIcon, zIndexOffset: 10000 });
				marker.addTo(map);
				pointMarkerRef.current = marker;
			} else {
				pointMarkerRef.current.setLatLng([coords.lat, coords.lng]);
			}
		} else if (type === 'kebele') {
			customIcon.options.className = 'custom-div-icon-red';
			marker = L.marker([coords.lat, coords.lng], { icon: customIcon, zIndexOffset: 10000 });
			marker.addTo(map);
		}
		return marker;
	};

	const makePanes = (panesArray) => {
		panesArray.forEach((pane) => {
			map.createPane(pane.name);
			const currentPane = map.getPane(pane.name);
			for (const [key, value] of Object.entries(pane.style)) {
				currentPane.style[key] = value;
			}
		});
	};
	// endregion

	const mapRef = useCallback((node) => {
		if (node !== null) {
			setMap(node);
		}
	}, []);

	return (
		<MapContainer 
			zoom={mapClass === 'large-map' ? 6 : 12}
			center={kebele.code > 0 ? [kebele.centroid.lat, kebele.centroid.lng] : position}
			scrollWheelZoom
			ref={mapRef}
		/>
	);
};

export default MapComponent;
