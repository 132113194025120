import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BackendFetch, DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import Gleap from 'gleap';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const tolgee = Tolgee()
	.use(DevTools())
	.use(FormatSimple())
	.use(BackendFetch())
	.init({
		language: 'en',
		apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
		apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
	});

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
	Gleap.initialize('pk43Ui6l5Gc5mXJZDJJVofNvQZDb9DSw');
}
// Please make sure to call this method only once!

root.render(
	<React.StrictMode>
		<TolgeeProvider tolgee={tolgee}>
			<App />
			{/* <RouterProvider router={router} /> */}
		</TolgeeProvider>
	</React.StrictMode>
);
