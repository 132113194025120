import React, { useEffect, useState } from 'react';
import './styles.css';
import { RadioButton } from 'primereact/radiobutton';
import DataService from '../../../../services/httpService/dataService';

const MapLayerPicker = (props) => {
	const { setAdminLayer } = props;

	const layers = [
		{ name: 'Region', key: 1 },
		{ name: 'Zone', key: 2 },
		{ name: 'Woreda', key: 3 },
	];

	const [activeLayer, setActiveLayer] = useState(layers[1]);

	useEffect(() => {
		DataService.getAdminPolygons(activeLayer.key)
			.then((response) => {
				setAdminLayer(response);
			});
	}, [activeLayer]);

	return (
		<div className="layer-picker white-box">
			{layers.map((layer) => {
				return (
					<div key={layer.key} className="picker-option">
						<RadioButton
							inputId={layer.key}
							id={layer.key}
							name="layer"
							value={layer}
							onChange={(e) => {
								setActiveLayer(e.value);
								setTimeout(() => {
									document.activeElement.blur();
								}, '200');
							}
							}
							checked={activeLayer.key === layer.key}
						/>
						<label htmlFor={layer.key} className="ml-2">{layer.name}</label>
					</div>
				);
			})}
		</div>
	);
};

export default MapLayerPicker;
