/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import './styles.css';

const MapDialogBoxPlot = (props) => {
	const { data } = props;

	useEffect(() => {
		const root = am5.Root.new('chartdiv-boxplot');
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		const chart = root.container.children.push(
			am5xy.XYChart.new(root, {
				focusable: true,
				panX: true,
				panY: true,
				wheelX: 'panX',
				wheelY: 'zoomX',
				id: 'xy',
			})
		);

		const xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				categoryField: 'category',
				renderer: am5xy.AxisRendererX.new(root, {}),
			})
		);

		const yAxis = chart.yAxes.push(
			am5xy.ValueAxis.new(root, {
				renderer: am5xy.AxisRendererY.new(root, {}),
			})
		);

		const color = root.interfaceColors.get('background');

		const series = chart.series.push(
			am5xy.CandlestickSeries.new(root, {
				fill: color,
				stroke: color,
				name: 'MDXI',
				xAxis,
				yAxis,
				valueYField: 'close',
				openValueYField: 'open',
				lowValueYField: 'min',
				highValueYField: 'max',
				categoryXField: 'category',
				tooltip: am5.Tooltip.new(root, {
					pointerOrientation: 'horizontal',
					labelText: 'Max: {highValueY}\n75%: {valueY}\nMdn: {median}\n25%: {openValueY}\nMin: {lowValueY}',
					autoTextColor: false,
				}),
			})
		);

		const medianSeries = chart.series.push(
			am5xy.StepLineSeries.new(root, {
				stroke: root.interfaceColors.get('background'),
				xAxis,
				yAxis,
				valueYField: 'median',
				categoryXField: 'category',
				noRisers: true,
				stepWidth: am5.percent(100),
				locationX: 0.25,
			})
		);

		const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
			xAxis,
		}));
		cursor.lineY.set('visible', false);

		series.data.setAll(data);
		medianSeries.data.setAll(data);

		series.columns.template.adapters.add('fill', (fill, target) => {
			return target._dataItem.dataContext.color;
		});
		series.columns.template.adapters.add('stroke', (fill, target) => {
			return target._dataItem.dataContext.color;
		});

		const categories = data.map((val) => {
			return { category: val.category };
		});

		root.numberFormatter.set('numberFormat', '#,###.##');

		xAxis.data.setAll(categories);

		series.appear(1000, 100);
		medianSeries.appear(1000, 100);
		chart.appear(1000, 100);

		// eslint-disable-next-line consistent-return
		return () => {
			root.dispose();
		};
	}, [data]);

	return (
		<div id="chartdiv-boxplot" />
	);
};

export default MapDialogBoxPlot;
