import React, { useState, useRef, useEffect } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useTranslate } from '@tolgee/react';
import SearchService from '../../services/httpService/searchService';
import DataService from '../../services/httpService/dataService';
import './styles.css';
import translate from '../../utils/translate';
import capitalizeStartingLetters from '../../utils/capitalizeStartingLetters';

const SearchBox = (props) => {
	const { setKebele, kebele } = props;
	const { t } = useTranslate();
	const [value, setValue] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [items, setItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isRecent, setIsRecent] = useState(true);
	const autoComplete = useRef();
	const clearTimer = useRef();

	useEffect(() => {
		if (value.length === 0) {
			if (kebele && kebele.code > 0) {
				const searchVal = {
					value: kebele.value,
					kebele_id: kebele.code,
					has_crop_data: kebele.has_crop_data,
				};
				setValue([searchVal]);
			}
		}
	}, []);

	const populateWithRecents = () => {
		const recentSearches = localStorage.getItem('recentSearches');
		if (recentSearches != null) {
			setIsRecent(true);
			setItems(JSON.parse(recentSearches));
		}
	};

	// on search complete event, refocus input
	useEffect(() => {
		const handleSearchComplete = () => {
			setTimeout(() => {
				autoComplete.current.focus();
			}, '700');
		};
		
		window.addEventListener('searchComplete', handleSearchComplete);

		return () => {
			window.removeEventListener('searchComplete', handleSearchComplete);
		};
	}, []);

	const search = (event) => {
		if (event.query.length > 2) {
			autoComplete.current.hide();
			setSearchTerm(event.query);
			const service = new SearchService();
			setLoading(true);
			service.getSuggestions(event.query)
				.then((response) => {
					setItems(response.data);
					setIsRecent(false);
					setLoading(false);
					setTimeout(() => {
						autoComplete.current.focus();
					}, '200');
				});
		} else {
			populateWithRecents();
		}
		// autoComplete.current.show();
		dispatchEvent(new Event('searchComplete'));
	};

	const saveRecentSearch = () => {
		let recentSearches = localStorage.getItem('recentSearches');
		const currentValue = value[0];
		if (recentSearches == null) {
			recentSearches = [currentValue]; // initialize recentSearches as array with current item as the only entry
		} else {
			recentSearches = JSON.parse(recentSearches);
			const existingItemIndex = recentSearches.findIndex((item) => item.kebele_id === currentValue.kebele_id);
			if (existingItemIndex === -1) {
				if (recentSearches.length > 4) {
					recentSearches.splice(4, 1); // remove the last item
					recentSearches.splice(0, 0, currentValue); // add most recent search as first item
				} else {
					recentSearches.splice(0, 0, currentValue);
				}
			} else {
				recentSearches.splice(existingItemIndex, 1); // remove current value from where it's currently at
				recentSearches.splice(0, 0, currentValue); // move it to the top of the recent array
			}
		}
		localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
	};

	const setSearchValue = () => {
		if (value instanceof Array && value.length > 0) {
			if (kebele.code === value[0].kebele_id) {
				setKebele({ ...kebele });
			} else {
				clearTimeout(clearTimer.current);
				// selected.current = true;
				saveRecentSearch();
				setLoading(true);
				DataService.getKebeleData(value[0].kebele_id)
					.then((response) => {
						console.log(response);
						setKebele(response);
					});
				setLoading(false);
			}
		}
	};

	const handleUnfocus = (event) => {
		clearTimer.current = setTimeout(() => {
			if (kebele.code > 0 && !event.target.value && value.kebele_id !== kebele.code) {
				setValue([{ has_crop_data: kebele.has_crop_data, kebele_id: kebele.code, value: kebele.value }]);
			}
		}, 200);
		autoComplete.current.getInput().removeEventListener('blur', handleUnfocus);
	};
	const handleKeyPress = (event) => {
		if (value.length > 0) {
			if (event.key === 'Enter') {
				setSearchValue();
			}
		}
	};

	// capture the case where the user presses backspace all the way to empty input.
	// in this case, we want to show recent suggestions. emptying input closes the suggestion
	// dropdown, so we use this function to re-open it after a delay.
	const handleKeyDown = (event) => {
		if (event.key === 'Backspace') {
			const inputValue = autoComplete.current.getInput().value;
			// input is not updated by the keypress at this point, so we take length === 1 because it
			// will be made empty by the backspace keypress.
			if (inputValue.length === 1) {
				setIsRecent(true);
				populateWithRecents();
				setTimeout(() => {
					autoComplete.current.show();
				}, 200);
			}
		}
		// const inputValue = autoComplete.current.getInput().value;
		// if (inputValue.length > 2) {
		// 	searching.current = true;
		// }
	};

	const optionTemplate = (data) => {
		console.log(data);
		return (
			<div className="option-template">
				{data.has_crop_data ? (
					<>
						<Tooltip target=".wheat-icon" />
						<i className="wheat-icon fad fa-wheat fa-flip-horizontal" data-pr-tooltip={translate(t, 'Crop data available for this kebele.')} data-pr-position="left" />
					</>

				)
					: null
				}
				<p className="suggestion-text" style={data.has_crop_data ? null : { marginLeft: '31px' }}>
					{
						isRecent ? (
						// eslint-disable-next-line react/jsx-no-useless-fragment
							<>{data.value}</>
						) : (
							<><span className="search-term">{`${capitalizeStartingLetters(searchTerm)}`}</span>{`${data.value.slice(searchTerm.length)}` }</>
						)
					}
				</p>
			</div>
		);
	};

	const valueTemplate = (data) => {
		return (
			<div className="value-template">
				{data.has_crop_data ? (
					<i className="fad fa-wheat fa-flip-horizontal" />
				)
					: null
				}
				<p>
					{ `${data.value}` }
				</p>
			</div>
		);
	};
	return (
		<div className="search-box">
			<AutoComplete
				ref={autoComplete}
				delay={500}
				className="search-field"
				value={value}
				suggestions={items}
				field="value"
				completeMethod={search}
				minLength={0}
				multiple
				selectionLimit={1}
				showEmptyMessage
				emptyMessage={translate(t, 'No values found.')}
				placeholder={value.length > 0 ? null : translate(t, 'Search kebele, eg. Mehoni Town')}
				itemTemplate={optionTemplate}
				removeIcon="fa-solid fa-xmark"
				selectedItemTemplate={valueTemplate}
				onSelect={(e) => {
					clearTimeout(clearTimer.current);
					setIsRecent(false);
					setValue([e.value]);
					autoComplete.current.getInput().addEventListener('blur', handleUnfocus);
				}}
				onKeyPress={(e) => handleKeyPress(e)}
				onFocus={() => {
					if (isRecent) {
						populateWithRecents();
					}
					autoComplete.current.show();
				}}
				onKeyDown={(e) => handleKeyDown(e)}
				disabled={loading}
				onUnselect={() => {
					clearTimeout(clearTimer.current);
					setValue([]);
					setIsRecent(true);
					autoComplete.current.getInput().addEventListener('blur', handleUnfocus);
				}}
				panelStyle={loading ? { opacity: '0.5', pointerEvents: 'none' } : null}
			/>
			<Button
				icon="fa-solid fa-magnifying-glass"
				text
				className="search-button"
				onClick={() => setSearchValue()}
				loading={loading}
				loadingIcon="fa-solid fa-spinner-third fa-spin"
				disabled={value.length === 0}
			/>
		</div>
	);
};

export default SearchBox;
