import './App.css';
import React, { useState, useEffect, Suspense, lazy } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css'; 
import '@fortawesome/fontawesome-pro/css/all.css';
import '@fortawesome/fontawesome-pro/css/fontawesome.css';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { MenuBar, Loader } from './components';
import { http } from './services/httpService';

const Home = lazy(() => import('./pages/Home'));

const Dashboard = lazy(() => import('./pages/Dashboard'));

const App = () => {
	const [loading, setLoading] = useState(false);
	let timer;

	useEffect(() => {
		const reqInterceptor = http.interceptors.request.use(
			(response) => {
				clearTimeout(timer);
				setLoading(true);
				return response;
			},
			(error) => {
				console.log(error);
				timer = setTimeout(() => {
					setLoading(false);
				}, 100);
				return Promise.reject(error);
			}
		);
		const respInterceptor = http.interceptors.response.use(
			(response) => {
				console.log(response);
				timer = setTimeout(() => {
					setLoading(false);
				}, 100);
				return response;
			},
			(error) => {
				console.log(error);
				timer = setTimeout(() => {
					setLoading(false);
				}, 100);
				return Promise.reject(error);
			}
		);
		return () => {
			http.interceptors.request.eject(reqInterceptor);
			http.interceptors.response.eject(respInterceptor);
		};
	}, []);

	// set up context
	const [kebele, setKebele] = useState({ code: '-1' });

	return (
		<div className="app">
			{loading ? <Loader /> : null}
			<HashRouter>
				<MenuBar />
				<Routes>
					<Route
						element={<Navigate to="/Home" replace />}
						path="/"
					/>
					<Route
						element={(
							<Suspense fallback={<div>Loading...</div>}>
								<Home kebele={kebele} setKebele={setKebele} loading={loading} />
							</Suspense>
						)}
						path="/Home"
					/>
					<Route
						element={(
							<Suspense fallback={<div>Loading...</div>}>
								<Dashboard kebele={kebele} setKebele={setKebele} />
							</Suspense>
						)}
						path="/Dashboard"
					/>
				</Routes>
			</HashRouter>
		</div>
	);
};

export default App;
