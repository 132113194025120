/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import './styles.css';

const MyComponent = (props) => {
	const { data } = props;
	
	useEffect(() => {
		const root = am5.Root.new('chartdiv-bar');
		root.setThemes([
			am5themes_Animated.new(root),
		]);

		const chart = root.container.children.push(am5xy.XYChart.new(root, {
			panX: false,
			panY: false,
			wheelX: 'panX',
			wheelY: 'zoomX',
		}));

		const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
			behavior: 'zoomX',
		}));
		cursor.lineY.set('visible', false);

		const xAxis = chart.xAxes.push(
			am5xy.CategoryAxis.new(root, {
				categoryField: 'category',
				renderer: am5xy.AxisRendererX.new(root, {}),
			})
		);

		const categories = data.map((val) => {
			return { category: val.category };
		});
		xAxis.data.setAll(categories);

		const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
			renderer: am5xy.AxisRendererY.new(root, {}),
		}));

		const series = chart.series.push(am5xy.ColumnSeries.new(root, {
			name: 'Series',
			xAxis,
			yAxis,
			valueYField: 'value',
			categoryXField: 'category',
			tooltip: am5.Tooltip.new(root, {
				labelText: '{valueY}',
				autoTextColor: false,
			}),
		}));

		series.columns.template.setAll({ strokeOpacity: 0 });

		series.columns.template.adapters.add('fill', (fill, target) => {
			return target._dataItem.dataContext.color;
		});
		series.columns.template.adapters.add('stroke', (fill, target) => {
			return target._dataItem.dataContext.color;
		});

		root.numberFormatter.set('numberFormat', '#,###.##');
		
		series.data.setAll(data);

		series.appear(1000);
		chart.appear(1000, 100);
		return () => {
			root.dispose();
		};
	}, [data]);
	return (
		<div id="chartdiv-bar" />
	);
};

export default MyComponent;
