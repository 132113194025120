import React from 'react';
import './styles.css';
import { SelectButton } from 'primereact/selectbutton';
import { Tooltip } from 'primereact/tooltip';

const VariablePicker = (props) => {
	const { variable, setVariable, variables } = props;
	const iconTemplate = (data) => {
		return (
			<i className={`select-item-icon ${data.icon}`} data-pr-tooltip={data.label} />
		);
	};

	return (
		<div className="var-picker">
			<Tooltip target=".select-item-icon" />
			<h3>Select Graph Variable: </h3>
			<SelectButton
				className="select-quarter"
				optionLabel="label"
				value={variable}
				onChange={(e) => setVariable(e.value)}
				options={variables}
				itemTemplate={iconTemplate}
				unselectable={false}
			/>
		</div>
	);
};

export default VariablePicker;
