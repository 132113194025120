import axios from 'axios';

class SearchService {
	constructor() {
		const BACKEND = process.env.REACT_APP_BACKEND_URL;

		this.http = axios.create({
			timeout: 30000,
			baseURL: `${BACKEND}`,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});
	}
	
	async getSuggestions(term) {
		const result = await this.http.get(`api/suggestions/${term}/10`);
		return result.data;
	}
}

export default SearchService;
